import styles from "../css/app.pcss";
import { tns } from "tiny-slider/src/tiny-slider";
var SimpleLightbox = require("simple-lightbox");

import SmoothScroll from "smooth-scroll/dist/smooth-scroll.js";
const scroll = new SmoothScroll("[data-scroll]");

// App main
const main = async () => {
  // Import our CSS
  //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
  // Async load the vue module
  // const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
  // Create our vue instance
  // const vm = new Vue.default({
  //     el: "#app",
  //     components: {
  //         'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
  //     },
  //     data: {
  //     },
  //     methods: {
  //     },
  //     mounted() {
  //     },
  // });
};
// Execute async function
main().then(value => {
  document.getElementById("hamburger").onclick = function() {
    document.getElementById("navbar").classList.toggle("opened");
    document.getElementById("hamburger").classList.toggle("open");
  };

  var talentToggles = document.querySelectorAll(".t-tog");
  talentToggles.forEach(function(el) {
    el.onclick = function() {
      var parent = el.parentElement;
      parent.classList.toggle("open");
    };
  });

  var navItems = document.querySelectorAll(".navitem");
  navItems.forEach(function(el) {
    el.onclick = function() {
      document.getElementById("navbar").classList.toggle("opened");
      document.getElementById("hamburger").classList.toggle("open");
    };
  });

  var lightbox = new SimpleLightbox({ elements: ".gallery .gallery-image" });

  var sliderArray = document.querySelectorAll(".image-slider");
  sliderArray.forEach(function(el) {
    tns({
      container: el,
      mouseDrag: true,
      prevButton: ".prev",
      nextButton: ".next",
      controls: true,
      nav: false,
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false
    });
  });

  window.addEventListener("scroll", throttle(navFixed, 100));

  function throttle(fn, wait) {
    var time = Date.now();
    return function() {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      }
    };
  }

  // Variables
  var nav = document.getElementById("header");

  // Functions
  function navFixed(e) {
    if (window.scrollY >= 10) {
      nav.classList.add("scroll");
    } else {
      nav.classList.remove("scroll");
    }
  }

  var scroll = new SmoothScroll('a[href*="#"]');
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
